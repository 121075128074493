<template>
  <div>
    <!--    <div style="margin:10px 0">-->
    <!--      <a-input placeholder="回车搜索" @keydown.enter="search" v-model="sValue"/>-->
    <!--    </div>-->
    <div style="margin: 15px 0">
      <a-button type="primary" gost style="margin-right: 15px" @click="user_add_status.show=true">添加用户</a-button>
      <a-input-search v-model="sValue" placeholder="回车搜索" @search="search" @keydown.enter="search"
                      style="width: 20%"/>

    </div>
    <div>
      <a-table
          ref="table"
          :columns="columns"
          :data-source="data"
          :rowKey="(record) => record.id"
          :pagination="false"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <!--        eslint-disable-next-line vue/no-unused-vars -->
        <template slot="operation" slot-scope="text, record">

          <a @click="open_editor(record.id)">编辑</a>

        </template>

      </a-table>
    </div>

    <a-pagination style="float: right;margin-top: 15px;"
                  v-if="!isSearch"
                  :default-current="1"
                  v-model="curr"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />

    <a-pagination style="float: right;margin-top: 15px;"
                  v-if="isSearch"
                  :default-current="1"
                  v-model="currSearch"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />


    <a-modal title="用户修改" :width="600" v-model="user_editor_status.show" @ok="user_editor_ok"
             @cancel="user_editor_cancel"
             okText="确定修改" cancelText="取消">
      <div style="margin:16px 0">
        <a-input addon-before="邮箱:" disabled="" :value="user_editor_status.user_tempObj.email"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="密码:" v-model="user_editor_status.user_tempObj.password" placeholder="更改用户密码"/>
      </div>


      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          是否登录:
        </div>

        <a-select style="width: 84.1%" v-model="user_editor_status.user_tempObj.status">
          <a-select-option :value="0">已登录</a-select-option>
          <a-select-option :value="1">未登录</a-select-option>
        </a-select>

      </div>


      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          过期时间:
        </div>

        <a-date-picker format="YYYY-MM-DD" style="width: 84%" @change="onChange"/>

      </div>

      <div>
        <a-button style="width: 100%" type="danger" ghost @click="deleteUser">删除</a-button>
      </div>


    </a-modal>

    <a-modal title="新增用户" :width="600" v-model="user_add_status.show" @ok="user_add_ok"
             @cancel="user_add_cancel"
             okText="确定添加" cancelText="取消">
      <div style="margin:16px 0">
        <a-input addon-before="邮箱:" v-model="user_add_status.temp.email"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="密码:" v-model="user_add_status.temp.password" placeholder="用户密码"/>
      </div>


      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          是否登录:
        </div>

        <a-select style="width: 84.1%" v-model="user_add_status.temp.status">
          <a-select-option :value="0">已登录</a-select-option>
          <a-select-option :value="1">未登录</a-select-option>
        </a-select>

      </div>


      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          过期时间:
        </div>

        <a-date-picker :locale="locate" format="YYYY-MM-DD" style="width: 84%" @change="date_add"/>

      </div>


    </a-modal>

  </div>

</template>


<script>
import 'moment/locale/zh-cn';
// eslint-disable-next-line no-unused-vars
import moment from 'moment'

const key = '123'
export default {
  name: "userList",
  data() {
    return {
      curr: 1,
      currSearch: 1,

      user_editor_status: {
        show: false,
        user_tempObj: {
          expiration_time: ''
        }
      },


      user_add_status: {
        show: false,
        temp: {
          status: 1,
          expiration_time: ''
        },
      },

      copyData: {},

      isSearch: false,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: "center",

        },
        {
          title: '邮箱',
          dataIndex: 'email',
          key: 'email',
          align: "center",
        },

        {
          title: '是否登录',
          dataIndex: 'status',
          key: 'status',
          align: "center",

        },
        {
          title: '过期时间',
          dataIndex: 'expiration_time',
          key: 'expiration_time.trim()',
          align: "center",

        },
        {
          title: '登录系统',
          dataIndex: 'operating_system',
          key: 'operating_system',
          align: "center",

        },
        {
          title: '登录浏览器',
          dataIndex: 'browser_name',
          key: 'browser_name',
          align: "center",
        },

        {
          title: '登录浏览器版本',
          dataIndex: 'browser_version',
          key: 'browser_version',
          align: "center",
        },
        {
          title: '',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          align: "center",

        },
      ],
      data: [],
      returnBack: [],
      sValue: ''
    }
  },
  methods: {
    user_add_ok() {
      this.$axios.post('/Admin/Api/VipUsers/addUser', {
            ...this.user_add_status.temp
          }
      ).then(v => {
        this.user_add_status.temp.id = `缓存数据,请刷新${new Date().getTime()}`
        this.data.unshift(this.user_add_status.temp)
        this.$message.success({content: v.data.msg, duration: 1, key})
        this.user_add_status.show = false
      }, r => {
        this.$message.error({content: r.data, duration: 1, key})
      })
    },
    user_add_cancel() {
      this.user_add_status.show = false
      this.user_add_status.temp = {
        status: 1
      }
    },
    deleteUser() {
      this.$axios.post('/Admin/Api/VipUsers/delUser', {
            id: this.user_editor_status.user_tempObj.id
          }
      ).then(v => {
        this.data.splice(this.data.findIndex(v => v.id == this.user_editor_status.user_tempObj.id), 1)
        this.$message.success({content: v.data.msg, duration: 1, key})
        this.user_editor_status.show = false
      }, r => {
        this.$message.error({content: r.data, duration: 1, key})
      })
    },
    date_add(v, r) {
      this.user_add_status.temp.expiration_time = r
    },
    onChange(v, r) {
      this.user_editor_status.user_tempObj.expiration_time = r
    },
    async search() {
      this.currSearch = 1
      this.isSearch = true
      await this.$axios.post('/Admin/Api/VipUsers/searchUser', {
        email: this.sValue,
        page: 1,
        pageCount: '12'
      }).then(val => {
        this.returnBack = JSON.parse(val.data.data)
        console.log(
            this.returnBack
        )
        this.data = this.returnBack.userList
      })
    },
    open_editor(key) {
      const temp = this.data.find(v => v.id == key)
      this.user_editor_status.user_tempObj = temp
      this.user_editor_status.user_tempObj.password = undefined
      Object.assign(this.copyData, temp)
      this.user_editor_status.show = true
    },

    user_editor_ok() {
      if (this.user_editor_status.user_tempObj.password == undefined) {
        this.user_editor_status.user_tempObj.password = '000'
      }
      if (!`${this.user_editor_status.user_tempObj.expiration_time}`.includes('-')) {
        this.user_editor_status.user_tempObj.expiration_time = moment(this.user_editor_status.user_tempObj.expiration_time * 1000).format('YYYY-MM-DD')
      }
      this.$axios.post('/Admin/Api/VipUsers/editUser', {
        ...this.user_editor_status.user_tempObj
      }).then(v => {
        this.$message.success(v.data.msg)
        this.user_editor_status.user_tempObj.password = undefined
      }).catch(err => {
        this.$message.success(err.msg)
        this.user_editor_cancel()
      })
      this.user_editor_status.user_tempObj = {}
      this.user_editor_status.show = false
    },

    user_editor_cancel() {
      this.$message.warning('取消修改', 1)
      const temp = this.data.find(v => v.id == this.copyData.id)
      temp.id = this.copyData.id
      temp.email = this.copyData.email
      temp.score = this.copyData.score
      temp.status = this.copyData.status
      temp.user_role = this.copyData.user_role

    }
  },
  watch: {
    curr: {
      async handler(n) {
        this.data = []
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/VipUsers/getUserList', {
          "page": n,
          "pageCount": 12
        })).data.data)
        this.returnBack.userList.forEach(v => {
          this.data.push({
            ...v,
          })
        })
      }
    },
    currSearch: {
      async handler(n) {
        this.data = []
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/VipUsers/searchUser', {
          "email": this.sValue,
          "page": n,
          "pageCount": 12
        })).data.data)
        this.isSearch = true
        this.data = this.returnBack.userList
      }
    },
  },

  // beforeUpdate() {
  //   this.data = this.data.map(v => ({
  //     ...v,
  //     status: v.status ? '未登录' : '已登录',
  //     expiration_time: moment(v.expiration_time * 1000).format('YYYY-MM-DD')
  //   }))
  // },
  async created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.38'
    this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/VipUsers/getUserList', {
      "page": 1,
      "pageCount": 12
    })).data.data)
    this.returnBack.userList.forEach(v => {
      this.data.push({
        ...v,
      })
    })

  }
}

</script>

<style scoped>

</style>
